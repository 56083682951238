import React from "react";

import "./Header.css";

type Props = {
    currentCase: any;
    diagnosticId: string | null;
};

export default function Header({ currentCase, diagnosticId }: Props) {
    const currentDiagnostic = currentCase.case_diagnostics[diagnosticId ?? ""];
    if (!diagnosticId || !currentCase || !currentDiagnostic) return null;

    const patient = currentCase.patient;
    const ichDiag = currentDiagnostic?.diagnostic_data.ich_diag;
    const lvoDiag = currentDiagnostic?.diagnostic_data.lvo_diag;
    const ichDiagRemark = currentDiagnostic?.diagnostic_data.ich_diag_remark;
    const lvoDiagRemark = currentDiagnostic?.diagnostic_data.lvo_diag_remark;
    const lesions = currentDiagnostic.diagnostic_data.lesions;

    function fnFormatDate(date: string) {
        if (!date) return "";
        var str = date;
        var year = parseInt(str.substring(0, 4));
        var month = parseInt(str.substring(4, 6));
        var day = parseInt(str.substring(6, 8));
        var hour = parseInt(str.substring(8, 10));
        var minute = parseInt(str.substring(10, 12));
        var newDate = new Date(year, month - 1, day, hour, minute);
    
        return newDate.toLocaleString("es-ES", { hour: "numeric", minute: "numeric", hour12: false });
    }

    return (
        <>
            <div id="dvHeader" className="topnav">
                <div className="topnavcol1">
                    <h5 id="txtTitle" className="topnavtext">
                        {patient?.name} - {currentDiagnostic?.image.type}
                    </h5>
                </div>
                <div className="topnavcol2">
                    <h5 id="txtSubtitle" className="topnavtext">
                        {fnFormatDate(currentDiagnostic?.image.datetime)} -{" "}
                        {patient?.gender} {patient?.age}
                    </h5>
                </div>
            </div>

            <div id="dvdiagnostic1" className="diagnosticdata1">
                {ichDiag && (
                    <div
                        id="dvichdiag"
                        className={`${
                            ichDiagRemark
                                ? "diagnosticdata1colremark"
                                : "diagnosticdata1col"
                        }`}
                    >
                        <h6
                            id="txtichdiag"
                            className={`${
                                ichDiagRemark
                                    ? "diagnosticdata1textremark"
                                    : "diagnosticdata1text"
                            }`}
                        >
                            {currentDiagnostic?.diagnostic_data.ich_diag}
                        </h6>
                    </div>
                )}
                {lvoDiag && (
                    <div
                        id="dvlvodiag"
                        className={`${
                            lvoDiagRemark
                                ? "diagnosticdata1colremark"
                                : "diagnosticdata1col"
                        }`}
                    >
                        <h6
                            id="txtlvodiag"
                            className={`${
                                lvoDiagRemark
                                    ? "diagnosticdata1textremark"
                                    : "diagnosticdata1text"
                            }`}
                        >
                            {currentDiagnostic?.diagnostic_data.lvo_diag}
                        </h6>
                    </div>
                )}
            </div>

            <div className="diagnostic2">
                <div id="dvdiagnostic2" className="diagnosticdata2">
                    {Object.keys(lesions)?.map((lesionName: string) => {
                        const isJet = lesions[lesionName].color === "jet";

                        return (
                            <div
                                className="diagnosticdata2col"
                                key={`lession_${lesionName}`}
                            >
                                <li
                                    className={`lidiagnosticdata2 ${
                                        isJet ? "lidiagnosticdata2jet" : ""
                                    }`}
                                    style={{
                                        color: lesions[lesionName].color
                                    }}
                                >
                                    <span className="diagnosticdata2text">
                                        {lesionName} {lesions[lesionName].ml} ml
                                    </span>
                                </li>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
