import React, { useEffect, useRef, useState } from "react";
import { AppState } from "../..";
import Header from "../header/Header";
import Tools from "../tools/Tools";

const cornerstoneNIFTIImageLoader = require("../../modules/cornerstone-nifti-image-loader/dist/cornerstoneNIFTIImageLoader.js");

const cornerstone = require("cornerstone-core");
const cornerstoneTools = require("cornerstone-tools");
const Hammer = require("hammerjs");
const cornerstoneMath = require("cornerstone-math");

import "./Diagnostic.css";

type Props = {
  currentCase: any;
  diagnosticId: string | null;
  setDiagnosticId: (id: string | null) => void;
  env: string | null;
  setAppState: (state: AppState) => void;
  token: string | null;
  setLoadingProgress: (value: number) => void;
  appState: AppState;
};

export type ToolsTypes = "stack" | "move" | "contr" | "zoomIn" | "reset";

export default function Diagnostic({
  currentCase,
  diagnosticId,
  setDiagnosticId,
  env,
  setAppState,
  setLoadingProgress,
  token,
  appState,
}: Props) {
  cornerstoneNIFTIImageLoader.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

  const [stack, setStack] = useState<any>({});
  const [activeTool, setActiveTool] = useState<ToolsTypes>("stack");
  const [rangeSliderValue, setRangeSliderValue] = useState<number>(1);
  const [rangeHeight, setRangeHeight] = useState<number>(0);
  const [isHorizontal, setIsHorizontal] = useState<boolean>(true);

  // useEffect for handling horizontalChange event
  const resizeImage = () => {
    const element = viewportRef.current;
    if (element) {
      const enabledElement = cornerstone.getEnabledElement(element);
      if (enabledElement && enabledElement.image) {
        cornerstone.resize(element, true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeImage);

    return () => {
      window.removeEventListener("resize", resizeImage);
    };
  }, []);

  // useEffect for loading image
  useEffect(() => {
    // Ensure both diagnosticId and currentCase are available
    if (diagnosticId && currentCase) {
      loadImage(diagnosticId);
    }
  }, [diagnosticId, currentCase]); // Dependencies array to trigger effect when these values change

  const viewportRef = useRef<HTMLDivElement | null>(null);

  const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;

  function loadImage(id: string) {
    const caseById = currentCase?.case_diagnostics?.[id ?? ""];

    if (!caseById) {
      setAppState("INVALID_PARAMS");
      return;
    }

    if (!id || !currentCase) return;
    const url = `nifti:${caseById?.diagnostic_url}`;
    const imageIdObject = ImageId.fromURL(url);
    const element = viewportRef.current;
    if (!element || !viewportRef) return;

    setRangeSliderValue(1);
    setAppState("LOADING");

    cornerstone.enable(element);

    cornerstone
      .loadAndCacheImage(url)
      .then(
        function (image: any) {
          const numberOfSlices = cornerstone.metaData.get(
            "multiFrameModule",
            imageIdObject.url
          ).numberOfFrames;
          const stack = {
            currentImageIdIndex: imageIdObject.slice.index,
            imageIds: Array.from(
              Array(numberOfSlices),
              (_, i) =>
                `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i}`
            ),
          };

          setStack(stack);

          const viewport = cornerstone.getDefaultViewportForImage(
            element,
            image
          );
          viewport.voi.windowWidth = caseById.image.default_windowing.width;
          viewport.voi.windowCenter = caseById.image.default_windowing.center;
          cornerstone.displayImage(element, image, viewport);

          cornerstoneTools.addStackStateManager(element, [
            "stack",
            "wwwc",
            "pan",
            "zoom",
          ]);

          cornerstoneTools.addToolState(element, "stack", stack);

          cornerstoneTools.mouseInput.enable(element);
          cornerstoneTools.touchInput.enable(element);
          cornerstoneTools.mouseWheelInput.enable(element);

          cornerstoneTools.orientationMarkers.enable(element);

          // Use stack tools as the initial active tool
          setActiveTool("stack");
          cornerstoneTools.stackScroll.activate(element, 1);
          cornerstoneTools.stackScrollWheel.activate(element);
          cornerstoneTools.stackScrollTouchDrag.activate(element);
          cornerstoneTools.stackScrollMultiTouch.activate(element);

          // Scroll to slice in the middle by default
          onChangeSlider(Math.floor(numberOfSlices / 2));

          setAppState("SUCCESS");
        },
        function (err: string) {
          setAppState("ERROR");
          console.error(err);
        }
      )
      .catch((err: string) => {
        setAppState("ERROR");
        console.error(err);
      });
  }

  useEffect(() => {
    if (!diagnosticId || !currentCase) return;

    loadImage(diagnosticId);
  }, [diagnosticId, currentCase]);

  // When scrolling the stack, update the rangeSlider
  useEffect(() => {
    const handleStackScroll = (event: CustomEvent) => {
      const { newImageIdIndex } = event.detail;

      setRangeSliderValue(newImageIdIndex + 1);
    };

    const element = document.getElementById("dicomImage");
    if (element) {
      element.addEventListener(
        cornerstoneTools.EVENTS.STACK_SCROLL,
        handleStackScroll as EventListener
      );
    }

    return () => {
      if (element) {
        element.removeEventListener(
          cornerstoneTools.EVENTS.STACK_SCROLL,
          handleStackScroll as EventListener
        );
      }
    };
  }, [viewportRef?.current]);

  // // When image is loading show the progress
  useEffect(() => {
    const handleImageLoadProgress = (event: CustomEvent) => {
      setLoadingProgress(event.detail.percentComplete);
    };

    cornerstone.events.addEventListener(
      "cornerstoneimageloadprogress",
      handleImageLoadProgress
    );

    return () => {
      cornerstone.events.removeEventListener(
        "cornerstoneimageloadprogress",
        handleImageLoadProgress
      );
    };
  }, []);

  function onChangeSlider(value: number) {
    const element = viewportRef.current;
    if (!element || !viewportRef) return;

    setRangeSliderValue(value);

    cornerstoneTools.scrollToIndex(element, value - 1);
  }

  // On load set the range slider height equal to that of the images
  useEffect(() => {
    if (viewportRef?.current) {
      setRangeHeight(viewportRef.current.offsetHeight - 40);
    }
  }, [viewportRef?.current]);

  if (
    !diagnosticId ||
    !currentCase ||
    !currentCase?.case_diagnostics?.[diagnosticId ?? ""]
  )
    return null;

  return (
    <>
      <Header currentCase={currentCase} diagnosticId={diagnosticId} />
      {appState === "SUCCESS" && (
        <Tools
          currentCase={currentCase}
          diagnosticId={diagnosticId}
          setRangeSliderValue={setRangeSliderValue}
          viewportRef={viewportRef}
          activeTool={activeTool}
          setActiveTool={setActiveTool}
        />
      )}
      <div className="row">
        <div className="column left" style={{ position: "relative" }}>
          <div
            id="dicomImage"
            ref={viewportRef}
            onContextMenu={(e) => e.preventDefault()}
            onMouseDown={(e) => e.preventDefault()}
          ></div>
          <p id="text" className="overlayText">
            Not for diagnostic use
          </p>
          <div className="sliderdiv">
            <svg
              className="arrow"
              viewBox="0 0 24 24"
              fill="white"
              onClick={() => {
                if (rangeSliderValue < stack?.imageIds?.length) {
                  onChangeSlider(rangeSliderValue + 1);
                }
              }}
            >
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
            </svg>
            <input
              id="myRange"
              type="range"
              style={{ height: `${rangeHeight}px` }}
              min="1"
              max={stack?.imageIds?.length}
              value={rangeSliderValue.toString()}
              className="slider"
              onChange={(e) => onChangeSlider(e.target.valueAsNumber)}
            />
            <p id="labelValueRange" className="labelValueRange">
              {`${rangeSliderValue}/${stack?.imageIds?.length ?? 0}`}
            </p>
            <svg
              className="arrow"
              viewBox="0 0 24 24"
              fill="white"
              onClick={() => {
                if (rangeSliderValue > 1) {
                  onChangeSlider(rangeSliderValue - 1);
                }
              }}
            >
              <path d="M15.41 7.41L10.83 12l4.58 4.59L14 18l-6-6 6-6z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="series">
        <div id="dvseries" className="seriestable">
          {Object.keys(currentCase.case_diagnostics).map(
            (caseDiagnosticNumber) => {
              const diagnostic =
                currentCase.case_diagnostics[caseDiagnosticNumber];
              const isActive = caseDiagnosticNumber === diagnosticId;
              const isDone = diagnostic.status === "DONE";

              return (
                <div
                  key={`serie_${caseDiagnosticNumber}`}
                  id={`serie_${caseDiagnosticNumber}`}
                  className={`seriescol  ${isActive ? "active" : ""} ${
                    isDone ? "" : "disabled"
                  }`}
                  onClick={() => {
                    if (isDone) {
                      loadImage(caseDiagnosticNumber);
                      setDiagnosticId(caseDiagnosticNumber);
                    }
                  }}
                >
                  <h6 className="typecol">{diagnostic.image.type}</h6>
                  <img
                    className="imageseries"
                    src={`data:image/jpeg;base64,${diagnostic.image.thumbnail}`}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="line"></div>
    </>
  );
}
