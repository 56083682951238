import React, { useEffect, useState, useRef } from "react";

import "./Landing.css";

import data from "../../mockups/example_diag_response.json";

import * as microsoft from "@microsoft/teams-js";
import { AppState } from "../..";
import { Case } from "../../types/types";

type Props = {
  setToken: (token: string | null) => void;
  setCurrentCase: (currentCase: any) => void;
  setDiagnosticId: (id: string | null) => void;
  setEnv: (env: string | null) => void;
  loadingProgress: number;
  appState: AppState;
  setAppState: (state: AppState) => void;
};

export default function Landing({
  setCurrentCase,
  appState,
  setAppState,
  setToken,
  setDiagnosticId,
  setEnv,
  loadingProgress,
}: Props) {
  const tokenRef = useRef<string | null>(null);
  const isInDevMode = process.env.REACT_APP_TEST_MODE === "true";

  const isLoading = appState === "LOADING";
  const error = appState === "ERROR";
  const invalidParams = appState === "INVALID_PARAMS";
  const isStale = appState === "STALE";
  const [userName, setUserName] = useState("");

  const [requestContent, setRequestContent] = useState<any>(
    <>
      <h4 className="requestText">
        You are not registered as a Methinks user.
      </h4>
      <div className="requestText" id="txtLanding">
        To gain access, please request approval from Methinks. You'll receive
        further instructions upon approval.
      </div>
      <div className="buttons">
        <button className="btn-request-access" onClick={handleRequestAccess}>
          Request Access
        </button>
      </div>
    </>
  );

useEffect(() => {
  microsoft.initialize();

  const url = window.location.href
  if (url.includes("configure")) {
    setAppState("CONFIGURE");
    microsoft.settings.registerOnSaveHandler(onSaveHandler);
    microsoft.settings.setValidityState(true);
    microsoft.appInitialization.notifySuccess();
    return
  }

  if (url.includes("help")) {
    setAppState("HELP");
    return
  }

  microsoft.authentication.getAuthToken({
    successCallback: function (result: any) {
      tokenReceived(result);
    },
    failureCallback: function (error: any) {
      setToken(null);
    },
  });

  if (isInDevMode) {
    const devToken = process.env.REACT_APP_MICROSOFT_DEV_TOKEN || "123";
    if (!devToken) {
      console.error("MICROSOFT_DEV_TOKEN not set");
      return;
    }
    tokenReceived(devToken);
  }
}, []);
  

  const onSaveHandler = (saveEvent: microsoft.settings.SaveEvent) => {
    const url =`${process.env[`REACT_APP_VIEWER_ENDPOINT`]}/?help`;
    microsoft.settings.setSettings({
        contentUrl: url,
        websiteUrl: url,
        suggestedDisplayName: "Methinks Help",
        entityId: "Methinks Help"
    });
    saveEvent.notifySuccess();
};

  const openSupportPortal = () => {
    window.open("https://methinksai.atlassian.net/servicedesk/customer/portal/2", "_blank");
  };

  async function handleRequestAccess() {
    try {
      const params = new URL(window.location.href).searchParams;
      const diagnostic_id = params.get("diagnostic_id");
      const env = params.get("env");

      const userToken = tokenRef.current;
      if (!diagnostic_id || !env || !userToken) {
        setAppState("INVALID_PARAMS");
        return;
      }

      const endpoint = `${
        process.env[`REACT_APP_STROKE_ENDPOINT_${env}`]
      }/ms_teams_viewer/request_access`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          key: "1234",
          token: userToken,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ diag_id: diagnostic_id }),
      });

      if (response.status !== 200) {
        setAppState("ERROR");
        console.error("Error:", response);
      } else {
        setRequestContent(
          <>
            <div className="requestText" id="txtLanding">
              Access request sent successfully!
              <br />
              <br />
              Please await contact from the Methinks team.
            </div>
          </>
        );
      }
      return;
    } catch (error) {
      setAppState("ERROR");
      console.error("Error:", error);
    }
  }

  async function tokenReceived(token: string) {
    const params = new URL(window.location.href).searchParams;
    var diagnostic_id = params.get("diagnostic_id");
    var env = params.get("env");
    tokenRef.current = token;

    //Check if there is a valid diagnostic_id and env in the url
    if (
      diagnostic_id == null ||
      diagnostic_id == "" ||
      env == null ||
      env == ""
    ) {
      // If there is no diagnostic_id or env, show the stale page (seen when the user opens the app)
      if (diagnostic_id == null && env == null) {
        setAppState("STALE");
      } else {
        setAppState("INVALID_PARAMS");
      }
      setDiagnosticId(null);
      setEnv(null);
    } else {
      setToken(token);
      setDiagnosticId(diagnostic_id);
      setEnv(env);

      // Set test case in development mode
      if (isInDevMode) {
        if (diagnostic_id === "99999") {
          setAppState("UNAUTHORIZED");
          return;
        } else {
          setCurrentCase(data);

          if (!(data as Case)?.case_diagnostics?.[diagnostic_id ?? ""]) {
            setAppState("INVALID_PARAMS");
          }
        }
      } else {
        await GetDiagnostic(token, diagnostic_id, env);
      }
    }
  }

  async function GetDiagnostic(
    token: string,
    diagnosticId: string,
    env: string
  ) {
    setAppState("LOADING");
    try {
      const endpoint = `${
        process.env[`REACT_APP_STROKE_ENDPOINT_${env}`]
      }/ms_teams_viewer/get_data`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          key: "1234",
          token: token,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ diag_id: diagnosticId }),
      });

      // if the user exists but is not authorized to view the diagnostic
      if (response.status === 401) {
        setAppState("UNAUTHORIZED");
        return;
      }

      if (response.ok) {
        const result = await response.json();
        setAppState("SUCCESS");
        setCurrentCase(result);

        if (!result.case_diagnostics?.[diagnosticId]) {
          setAppState("INVALID_PARAMS");
        }
      } else {
        setAppState("ERROR");
        console.error("Error:", response);
      }
    } catch (error) {
      setAppState("ERROR");
      console.error("Error:", error);
    }
  }

  return (
    <>
      {appState === "CONFIGURE" && (
        <div className="landing ">
          <img alt="Logo" src="./assets/logo.png" style={{ width: '80px', height: '80px' }} />
          <div className="unauthorized initialScreen">
            <h3>Get Started with the Methinks App</h3>
            <p>
              To get started with the app an active subscription of Methinks is
              required.
            </p>
            <p>
              Please contact us at{" "}
              <a href="mailto:support@methinks.ai">support@methinks.ai</a> to
              get started in case you do not have an active subscription.
            </p>
          </div>
        </div>
      )}
      {appState === "HELP" && (
        <div className="landing" id="dvLanding">
        <div className="help initialScreen">
          <h4>Need help with Methinks App?</h4>
          <div className="button-container">
            <button className="btn-open-support" onClick={openSupportPortal}> Contact Support </button>
          </div>

          <h4>How to use the Methinks App?</h4>
          <p>
            If you already have a subscription, please follow these steps to start:
          </p>
          <ol>
            <li>Open the channel with the app installed.</li>
            <li>Go to a Methinks notification displaying the AI results.</li>
            <li>To open the viewer, click on the "eye" button.</li>
            <li>Explore the viewer's features and functionalities.</li>
          </ol>
          <p>
            If you do not have an active subscription, please contact us at{" "}
            <a href="mailto:support@methinks.ai">support@methinks.ai</a>.
          </p>
        </div>
        </div>
      )}
      {isLoading && (
        <div id="dvLoading" className="loading">
          <div className="loader"></div>
          <br />
          <h4 id="txtloader" className="loadertext">
            DICOM Load Progress: {loadingProgress}%
          </h4>
        </div>
      )}
      {error && (
        <div className="landing" id="dvLanding">
          <img alt="Logo" src="./assets/logo.png" />
          <h4 className="landingtext" id="txtLanding">
            Oops! Something went wrong.
          </h4>
          <p className="landingSubtitle" id="landingSubtitle">
            For assistance, reach out to Methinks at support@methinks.ai
          </p>
        </div>
      )}
      {isStale && (
        <div className="landing" id="dvLanding">
          <img alt="Logo" src="./assets/logo.png" />
          <h2 className="landingtext" id="txtLanding">
            Methinks AI viewer
          </h2>
          <p className="landingSubtitle" id="txtLandingSub">
            Explore detailed insights by opening a Methinks AI result
          </p>
        </div>
      )}
      {invalidParams && (
        <div className="landing" id="dvLanding">
          <img alt="Logo" src="./assets/logo.png" />
          <h3 className="landingtext" id="txtLanding">
            Methinks AI viewer
          </h3>
          <p className="landingSubtitle" id="txtLandingSub">
            Please open a valid Methinks AI result
          </p>
        </div>
      )}
      {appState === "UNAUTHORIZED" && (
        <div className="landing" id="dvLanding">
          <img alt="Logo" src="./assets/logo.png" />
          <div className="unauthorized">{requestContent}</div>
        </div>
      )}
    </>
  );
}
