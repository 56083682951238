import "./styles/styles.css";
import "./styles/style_normalize.css";

import React, { useState } from "react";
import { createRoot } from "react-dom/client";

import Landing from "./components/landing/Landing";
import Diagnostic from "./components/diagnostic/Diagnostic";

export type AppState =
  | "LOADING"
  | "STALE"
  | "INVALID_PARAMS"
  | "ERROR"
  | "SUCCESS"
  | "UNAUTHORIZED"
  | "CONFIGURE"
  | "HELP";


const App = () => {
    const [appState, setAppState] = useState<AppState>("STALE");
    const [token, setToken] = useState<null | string>(null);
    const [currentCase, setCurrentCase] = useState<any>(null);
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [diagnosticId, setDiagnosticId] = useState<null | string>(null);
    const [env, setEnv] = useState<null | string>(null);

    return (
        <>
            <Diagnostic
                currentCase={currentCase}
                setAppState={setAppState}
                diagnosticId={diagnosticId}
                setDiagnosticId={setDiagnosticId}
                env={env}
                token={token}
                setLoadingProgress={setLoadingProgress}
                appState={appState}
            />

            <Landing
       
                setToken={setToken}
                setCurrentCase={setCurrentCase}
                setDiagnosticId={setDiagnosticId}
                setEnv={setEnv}
                loadingProgress={loadingProgress}
                appState={appState}
                setAppState={setAppState}
            />
        </>
    );
};

const rootElement = document.getElementById("root");

if (rootElement) {
    createRoot(rootElement).render(<App />);
}
