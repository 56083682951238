import React, { useEffect, useState } from "react";

const cornerstone = require("cornerstone-core");
const cornerstoneTools = require("cornerstone-tools");

import { ToolsTypes } from "../diagnostic/Diagnostic";

import "./Tools.css";

type Props = {
    currentCase: any;
    diagnosticId: string | null;
    setRangeSliderValue: (value: number) => void;
    viewportRef: React.RefObject<HTMLDivElement>;
    activeTool: ToolsTypes;
    setActiveTool: (tool: ToolsTypes) => void;
};

export default function Tools({
    currentCase,
    diagnosticId,
    setRangeSliderValue,
    viewportRef,
    activeTool,
    setActiveTool
}: Props) {
    const currentDiagnostic = currentCase.case_diagnostics[diagnosticId ?? ""];
    if (!diagnosticId || !currentDiagnostic) return null;

    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const allowWindowing = currentDiagnostic.allow_windowing;

    function cornerstoneToolsActions(actionType: ToolsTypes) {
        const element = viewportRef?.current;
        if (!element || !viewportRef) return;

        cornerstoneTools.pan.deactivate(element, 1);
        cornerstoneTools.panTouchDrag.deactivate(element);
        cornerstoneTools.wwwc.deactivate(element, 1);
        cornerstoneTools.wwwcTouchDrag.deactivate(element);
        cornerstoneTools.zoom.deactivate(element, 1);
        cornerstoneTools.zoomWheel.deactivate(element);
        cornerstoneTools.zoomTouchDrag.deactivate(element);
        cornerstoneTools.stackScroll.deactivate(element, 1);
        cornerstoneTools.stackScrollWheel.deactivate(element);
        cornerstoneTools.stackScrollTouchDrag.deactivate(element);
        cornerstoneTools.stackScrollMultiTouch.deactivate(element);

        if (actionType === "reset") {
            cornerstone.reset(element);
            cornerstoneToolsActions("stack");
            defaultFilter();
        }

        if (actionType === "move") {
            setActiveTool("move");
            cornerstoneTools.pan.activate(element, 1);
            cornerstoneTools.panTouchDrag.activate(element);
        }

        if (actionType == "contr") {
            setActiveTool("contr");
            cornerstoneTools.wwwc.activate(element, 1);
            cornerstoneTools.wwwcTouchDrag.activate(element);
        }

        if (actionType === "zoomIn") {
            setActiveTool("zoomIn");
            cornerstoneTools.zoom.activate(element, 1);
            cornerstoneTools.zoomWheel.activate(element);
            cornerstoneTools.zoomTouchDrag.activate(element);
        }

        if (actionType == "stack") {
            setActiveTool("stack");
            cornerstoneTools.stackScroll.activate(element, 1);
            cornerstoneTools.stackScrollWheel.activate(element);
            cornerstoneTools.stackScrollTouchDrag.activate(element);
            cornerstoneTools.stackScrollMultiTouch.activate(element);
        }
    }

    function fnfilter(windowsWidth: number, windowCenter: number) {
        const element = viewportRef.current;
        if (!element || !viewportRef) return;

        const viewport = cornerstone.getViewport(element);
        viewport.voi.windowWidth = windowsWidth;
        viewport.voi.windowCenter = windowCenter;
        cornerstone.setViewport(element, viewport);
    }

    function defaultFilter() {
        const windowingWidth = currentDiagnostic.image.default_windowing.width;
        const windowingCenter = currentDiagnostic.image.default_windowing.center;

        fnfilter(windowingWidth, windowingCenter);
    }

    const isElement = (element: EventTarget | null): element is Element => {
        return element instanceof Element;
    };

    // If the user clicks anywhere outside the dropdown, close it
    const handleDocumentClick = (event: MouseEvent) => {
        if (isElement(event.target) && !event?.target?.matches("#imgFilter")) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <div id="dvButtons" className="navbuttons">
            <button
                id="stack"
                type="button"
                className={`navbutton ${
                    activeTool === "stack" ? "active" : ""
                }`}
            >
                <img
                    src="./assets/stack.png"
                    className={`imagebutton`}
                    onClick={() => cornerstoneToolsActions("stack")}
                />
            </button>
            <button
                id="move"
                type="button"
                className={`navbutton ${activeTool === "move" ? "active" : ""}`}
            >
                <img
                    src="./assets/move.png"
                    className={`imagebutton`}
                    onClick={() => cornerstoneToolsActions("move")}
                />
            </button>
            <button
                id="contr"
                type="button"
                className={`navbutton ${
                    activeTool === "contr" ? "active" : ""
                } ${allowWindowing ? "" : "disabled"}`}
            >
                <img
                    id="imgcontr"
                    src="./assets/contraste.png"
                    className={`imagebutton`}
                    onClick={() => {
                        if (allowWindowing) {
                            cornerstoneToolsActions("contr");
                        }
                    }}
                />
            </button>
            <div className="dropdown">
                <button
                    id="btnFilter"
                    className={`navbutton ${allowWindowing ? "" : "disabled"}`}
                    onClick={() => {
                        if (allowWindowing) {
                            setShowDropdown(!showDropdown);
                        }
                    }}
                >
                    <img
                        src="./assets/filter.png"
                        id="imgFilter"
                        className={`imagebutton`}
                    />
                </button>
                <div
                    id="drpFilter"
                    className={`dropdown-content ${
                        showDropdown ? "show" : ""
                    } `}
                >
                    <a
                        id="filterDefault"
                        onClick={() => {
                            defaultFilter();
                        }}
                    >
                        Default
                    </a>
                    <a id="filterBrain" onClick={() => fnfilter(40, 40)}>
                        Stroke
                    </a>
                    <a id="filterSoft" onClick={() => fnfilter(80, 40)}>
                        Brain
                    </a>
                    <a id="filterBone" onClick={() => fnfilter(2800, 600)}>
                        Bone
                    </a>
                    <a id="filterContrast" onClick={() => fnfilter(500, 180)}>
                        Contrast
                    </a>
                </div>
            </div>
            <button
                id="zoomIn"
                type="button"
                className={`navbutton ${
                    activeTool === "zoomIn" ? "active" : ""
                }`}
            >
                <img
                    src="./assets/ZoomIn.png"
                    className="imagebutton"
                    onClick={() => cornerstoneToolsActions("zoomIn")}
                />
            </button>
            <button
                id="reset"
                type="button"
                className="navbutton"
                onClick={() => cornerstoneToolsActions("reset")}
            >
                <img src="./assets/reset.png" className="imagebutton" />
            </button>
        </div>
    );
}
